@import "~react-image-gallery/styles/css/image-gallery.css";
@import 'root.css';

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-VariableFont_wght.ttf');
  font-display: swap;
}

* {
  font-family: 'Montserrat' !important;
}

body {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gallery .image-gallery-slides,
.gallery .image-gallery-slide-wrapper,
.gallery .image-gallery-content,
.gallery .image-gallery,
.gallery,
.gallery .image-gallery-slide {
  height: 100%;
}

*,
*::before,
*::after {
  margin: 0;
}

.header {
  color: var(--black);
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 2rem;
  padding-left: 2rem;
  align-items: center;
  gap: 1.5rem;
  display: flex;
  z-index: 1;
}

.header .breadcrumbs {
  color: var(--white) !important;
}

.header .breadcrumbs a {
  color: var(--white) !important;
}

.page {
  width: 40%;
  min-height: 100vh;
  min-width: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

/* Стили для телефонов */
@media (max-width: 480px) {
  .page {
    min-width: 100vw;
  }

  .header {
    padding-top: 1rem;
    padding-left: 1rem;
  }
}

/* 
  Утилитарные стили для элементов которые должны быть 
  использованы при вызове window.print или нажатии ctrl + p
*/
@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}
