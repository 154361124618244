:root {
    --nigga-primary:#05919e;
    --primary: #00BACC;
    --white: #ffffff;
    --green: #015a4a;
    --black: #000000;
    --gray: #a8a8a8;
    --light-gray: #e6e6e6;
    --shadow: 0.5em 0.5em 2em rgba(0, 0, 0, 0.3);
    --inset-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}