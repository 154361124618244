@import 'root.css';

.gallery-container {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.gallery-controls-container {
    position: absolute;
    bottom: 0;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.gallery-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.gallery-controls .control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--white);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.gallery-controls .control:hover {
    background-color: rgba(0, 0, 0, 0.3);
}


.gallery .photos {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    scroll-behavior: smooth;
    margin-top: 20px;
    padding-bottom: 20px;
}

.gallery .photo {
    flex-shrink: 0;
    width: 120px;
    height: 80px;
    border-radius: 10px;
    border: 2px solid var(--white);
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.gallery .photo:hover {
    transform: scale(1.1);
}

.gallery-thumbnails {
    overflow-x: hidden;
    align-items: center;
    height: 12%;
    justify-items: center;
    width: 80%;
}

.image-gallery-icon {
    z-index: 0;
}

.gallery-thumbnail {
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    height: 70% !important;
    aspect-ratio: 1 / 1;
    border: 2px solid var(--white);
}

.selected {
    height: 90% !important;
}

.Product {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    max-height: 100vh;
    /* padding:2rem; */
    width: 70vw;
}

.Product .info {
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.Product::-webkit-scrollbar {
    width: 0.4em;
}

.Product::-webkit-scrollbar-track {
    box-shadow: var(--inset-shadow);
}

.Product::-webkit-scrollbar-thumb {
    width: 0.5em;
    background-color: #7e7b7b9c;
    border-radius: 20px;
}

.Product::-webkit-scrollbar-button {
    display: none;
}

.css-eg0mwd-MuiSlider-thumb.Mui-disabled {
    display: none;
}

.roomTable,
th,
td {
    border: 1px solid var(--nigga-primary);
    border-collapse: collapse;
    padding: 1rem
}


.roomTable {
    width: 100%;
}

.ImageList-item:hover img {
    filter: brightness(0.5);
}

.ImageList-item:hover::after {
    content: attr(data-title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--white);
    padding: 10px;
    border-radius: 5px;
}


.gallery .image-gallery-thumbnails-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 250px;
    width: 40%;
    margin: 0 auto;
}

.gallery .image-gallery-swipe {
    height: 100%;
}

.gallery .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: cover !important;
}

.gallery .image-gallery-image {
    height: 100vh;
    max-height: 100vh !important;
}

.RoomModal .gallery .image-gallery-image {
    height: 100%;
    max-height: 100vh !important;
}


.gallery .image-gallery-svg {
    width: 2.5rem !important;
    height: 2.5rem !important;
}

.gallery .image-gallery-left-nav,
.gallery .image-gallery-right-nav {
    padding: 0 !important;
    bottom: 0 !important;
    top: 95% !important;
}

.gallery .image-gallery-left-nav {
    left: 25%;
}

.gallery .image-gallery-right-nav {
    right: 25%;
}


.gallery .image-gallery-thumbnail.active,
.gallery .image-gallery-thumbnail:focus {
    outline: none;
    border: 3px solid var(--white);
}

.gallery .image-gallery-thumbnail.active {
    width: 70px !important;
}

.gallery .image-gallery-thumbnail {
    border: 1px solid var(--white);
    border-radius: 5px;
    width: 60px !important;
    overflow: hidden;
}

.gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
    aspect-ratio: 1 / 1;
}

.gallery .image-gallery-thumbnail:hover {
    outline: none;
    border: 3px solid var(--white);
}

.gallery .image-gallery-fullscreen-button .image-gallery-svg {
    width: 2rem !important;
    height: 2rem !important;
}

.gallery .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 1.5rem;
}

.image-gallery-index {
    top: auto;
    z-index: 0;
    bottom: 0;
}

.gallery {
    position: relative;
    height: 100%;
}

.hotel-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sideBar {
    box-sizing: border-box;
    padding: 35px;
    height: 100vh;
    max-height: 100vh;
    border: 1px solid var(--light-gray);
    width: 30vw;
    overflow: auto;
}

.sideBar .TotalBar {
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-top: 1px solid var(--light-gray);
    padding-top: 1rem;
}

.Reserve {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.Reserve a {
    color: var(--primary);
    font-weight: 600;
    text-decoration: none;
}

.sideBar::-webkit-scrollbar {
    width: 0.4em;
}

.sideBar::-webkit-scrollbar-track {
    box-shadow: var(--inset-shadow);
}

.sideBar::-webkit-scrollbar-thumb {
    width: 0.5em;
    background-color: #7e7b7b9c;
    border-radius: 20px;
}

.sideBar::-webkit-scrollbar-button {
    display: none;
}

.Activity {
    box-sizing: border-box;
    width: 20%;
    height: auto;
    aspect-ratio: 1 / 1;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.subitem {
    list-style: none;
    counter-reset: subitem;
}

.subitem>li:before {
    content: "1." counter(subitem) ". ";
    counter-increment: subitem;
}

.learn_more li>a {
    color: var(--black) !important;
    /* text-decoration-line: none; */
}

.hotel-container {
    padding: 2rem;
}

.hotel-map {
    height: 50vh !important;
    width: 100%;
}

.amenities-block {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
    padding-bottom: 0.5rem;
}

.amenities-modal {
    background-color: #fff;
    width: 60vw;
    height: 90vh;
    border-radius: 5px;


    overflow: auto;
}

.amenities-body {
    box-sizing: border-box;
    padding: 1rem;
}

.amenities-header {
    padding: 1rem;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
}

.amenities-block>div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.side-skeleton {
    width: 30vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 35px;
    border: 1px solid #e6e6e6;
    height: 100vh;
    gap: 1rem;
}


/* Стили для телефонов */
@media (max-width: 480px) {
    .Product .info {
        padding: 1rem;
    }

    .sideBar {
        display: none;
    }

    .Product {
        width: 100vw
    }

    .hotel-container {
        padding: 2rem;
    }

    .gallery .image-gallery-image {
        height: 100%;
    }

    .hotel-map {
        height: 30vh !important;
        width: 100%;
    }

    .amenities-block {

        grid-template-columns: auto;
    }

    .Reserve {
        padding-top: 0;
        font-size: 0.8rem;
        overflow: auto;
    }

    .amenities-modal {
        width: 100vw;
    }

    .hotel-page {
        justify-content: space-between;
        height: 100vh;
        flex-direction: column;
    }

    .side-skeleton {
        border: 1px solid #e6e6e6;
        height: 100px;
        flex-direction: column;
        position: sticky;
        bottom: 0;
        width: 100vw;
        overflow: hidden;
    }

    .gallery .image-gallery-slide .image-gallery-image {
        height: 35vh !important;
    }
}

/* Стили для планшетов в портретной ориентации */
@media (min-width: 481px) and (max-width: 768px) {
    .Product .info {
        padding: 1rem;
    }

    .sideBar {
        display: none;
    }

    .Product {
        width: 100vw
    }

    .hotel-container {
        padding: 2rem;
    }

    .gallery .image-gallery-image {
        height: 100%;
    }

    .hotel-map {
        height: 30vh !important;
        width: 100%;
    }

    .Reserve {
        padding-top: 0;
        font-size: 0.9rem;
        max-height: 70px;
        overflow: auto;
    }

    .amenities-modal {
        width: 100vw;
    }


    .hotel-page {
        justify-content: space-between;
        height: 100vh;
        flex-direction: column;
    }

    .gallery .image-gallery-slide .image-gallery-image {
        height: 35vh !important;
    }

}

/* Стили для планшетов в альбомной ориентации и ноутбуков */
@media (min-width: 769px) and (max-width: 1024px) {

    .sideBar {
        display: none;
    }

    .Product {
        width: 100vw
    }

    .hotel-container {
        padding: 2rem;
    }

    .gallery .image-gallery-image {
        height: 100%;
    }
}